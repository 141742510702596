.home {
  background-image: url("../../assets/images/homebg.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}

.home-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: rgb(15, 14, 14);
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 2px 2px 2px #ccc;
  padding: 20px;
  border-radius: 15px;
}

.tokenImg {
  width: 45px;
  height: 100%;
}

.timestamp {
  font-size: 14px;
  color: rgb(65, 63, 63);
}

.moment-ago {
  font-size: 12px;
  color: rgb(51, 51, 51);
}
