.filter-row {
  width: 100%;
  border: 1px solid #ccc;
}
.filter-date-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.filter-date-range {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin-left: 20px;
}

.filter-row-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}

.filter-locations-options {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  padding: 10px;
}
