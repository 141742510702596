.home { 
    background-image: url('../../assets/images/homebg.jpeg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  
}

.home-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: rgb(15, 14, 14);
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 2px 2px 2px #ccc;
    padding: 20px;
    border-radius: 15px;
    width: 80%;
    margin-top: 20px;
}

.about-scroll {
  width: 100%;
  height: 100%;
  max-height: 500px;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
}