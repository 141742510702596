@keyframes pulse {
  0% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  50% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.loading-logo {
  width: 150px;
  animation: pulse 1s linear infinite;
}

.loading-div {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
